export default {
  chevron: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4692 15.5339L5.99719 10.0608L7.05796 9.00024L11.9996 13.9428L16.9412 9.00024L18.0019 10.0608L12.5299 15.5339C12.3893 15.6746 12.1985 15.7536 11.9996 15.7536C11.8006 15.7536 11.6098 15.6746 11.4692 15.5339Z" fill="#868B95"/>
  </svg>
  `,

  chevronBlack: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4692 15.5339L5.99719 10.0608L7.05796 9.00024L11.9996 13.9428L16.9412 9.00024L18.0019 10.0608L12.5299 15.5339C12.3893 15.6746 12.1985 15.7536 11.9996 15.7536C11.8006 15.7536 11.6098 15.6746 11.4692 15.5339Z" fill="#101828"/>
  </svg>
  `,

  vk: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4 0C2.1 0 0 2.1 0 8.4V12.6C0 18.9 2.1 21 8.4 21H12.6C18.9 21 21 18.9 21 12.6V8.4C21 2.1 18.9 0 12.6 0H8.4ZM3.478 6.5625C3.59175 12.0225 6.3217 15.3038 11.108 15.3038H11.3793V12.18C13.138 12.355 14.4679 13.6413 15.0017 15.3038H17.4867C16.8042 12.8187 15.0104 11.445 13.8905 10.92C15.0104 10.2725 16.5854 8.6975 16.9617 6.5625H14.7041C14.2142 8.295 12.7618 9.87 11.3793 10.0188V6.5625H9.12171V12.6175C7.7217 12.2675 5.95425 10.57 5.8755 6.5625H3.478Z" fill="#343B48"/>
  </svg>
  `,

  telegram: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4 0C2.1 0 0 2.1 0 8.4V12.6C0 18.9 2.1 21 8.4 21H12.6C18.9 21 21 18.9 21 12.6V8.4C21 2.1 18.9 0 12.6 0H8.4ZM9.06248 12.8508C8.88006 12.7166 8.86701 12.4487 9.03552 12.2974L13.8227 7.99915C14.0452 7.80215 13.7747 7.69315 13.4772 7.89015L7.43326 11.6717C7.2568 11.7821 7.04095 11.8101 6.84218 11.7483L4.43362 10.9992C3.84158 10.8157 3.83058 10.4107 4.55712 10.1287L15.2028 6.05315C15.6958 5.86965 16.1264 6.16265 15.9658 6.91065L14.1547 15.3967C14.0182 15.9957 13.6617 16.1442 13.1557 15.8622L9.06248 12.8508Z" fill="#343B48"/>
  </svg>
  `,

  zen: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2216_2179)">
  <path d="M12.525 12.525C10.875 14.2275 10.755 16.35 10.6125 21C14.9475 21 17.94 20.985 19.485 19.485C20.985 17.94 21 14.805 21 10.6125C16.35 10.7625 14.2275 10.875 12.525 12.525ZM0 10.6125C0 14.805 0.015 17.94 1.515 19.485C3.06 20.985 6.0525 21 10.3875 21C10.2375 16.35 10.125 14.2275 8.475 12.525C6.7725 10.875 4.65 10.755 0 10.6125ZM10.3875 0C6.06 0 3.06 0.015 1.515 1.515C0.015 3.06 0 6.195 0 10.3875C4.65 10.2375 6.7725 10.125 8.475 8.475C10.125 6.7725 10.245 4.65 10.3875 0ZM12.525 8.475C10.875 6.7725 10.755 4.65 10.6125 0C14.9475 0 17.94 0.015 19.485 1.515C20.985 3.06 21 6.195 21 10.3875C16.35 10.2375 14.2275 10.125 12.525 8.475Z" fill="#343B48"/>
  <path d="M21 10.6125V10.3875C16.35 10.2375 14.2275 10.125 12.525 8.475C10.875 6.7725 10.755 4.65 10.6125 0H10.3875C10.2375 4.65 10.125 6.7725 8.475 8.475C6.7725 10.125 4.65 10.245 0 10.3875V10.6125C4.65 10.7625 6.7725 10.875 8.475 12.525C10.125 14.2275 10.245 16.35 10.3875 21H10.6125C10.7625 16.35 10.875 14.2275 12.525 12.525C14.2275 10.875 16.35 10.755 21 10.6125Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_2216_2179">
  <rect width="21" height="21" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  `,

  youtube: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4 0C2.1 0 0 2.1 0 8.4V12.6C0 18.9 2.1 21 8.4 21H12.6C18.9 21 21 18.9 21 12.6V8.4C21 2.1 18.9 0 12.6 0H8.4ZM8 7.59253V13.5325C8 13.9995 8.52958 14.2823 8.93397 14.0313L13.719 11.0614C14.0937 10.8288 14.0937 10.2962 13.719 10.0636L8.93397 7.09367C8.52958 6.84268 8 7.12554 8 7.59253Z" fill="#343B48"/>
  </svg>
  `,

  arrowRight: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1827 12.749L12.9711 16.9388L14.029 18.0022L19.5289 12.5307C19.6704 12.39 19.75 12.1986 19.75 11.999C19.75 11.7994 19.6704 11.6081 19.5289 11.4673L14.0289 5.99585L12.9711 7.05926L17.1826 11.249L4 11.249L4 12.749L17.1827 12.749Z" fill="#7B939B"/>
  </svg>`,

  triangleRight: `<svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.6369 9.2579C16.7877 10.1218 16.7877 11.8782 15.6369 12.7421L3.86907 21.5764C2.47158 22.6255 0.500001 21.606 0.500001 19.8343L0.500001 2.16574C0.500001 0.394005 2.47158 -0.625477 3.86907 0.423631L15.6369 9.2579Z" fill="#101828" fill-opacity="0.1"/>
  </svg>
  `,

  plus: `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.75 12.75V18H13.25V12.75H18.5V11.25H13.25V6H11.75V11.25H6.5V12.75H11.75Z" fill="#273A64"/>
  </svg>
  `,

  mapMarker: `
  <svg width="40" height="55" viewBox="0 0 70 88" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M35 0C15.6705 0 0 15.5819 0 34.8026C0 54.0231 30.3377 88 35 88C39.6623 88 70 54.0231 70 34.8026C70 15.5821 54.3297 0 35 0ZM35 47.2329C27.406 47.2329 21.2511 41.1126 21.2511 33.5615C21.2511 26.0079 27.406 19.8875 35 19.8875C42.594 19.8875 48.7489 26.0078 48.7489 33.5615C48.7489 41.1094 42.594 47.2329 35 47.2329Z" fill="#7B939B"/></svg>
  `,

  mapMarkerBordered: `
  <svg width="40" height="55" viewBox="0 0 70 88" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path id="core" d="M35 0C15.6705 0 0 15.5819 0 34.8026C0 54.0231 30.3377 88 35 88C39.6623 88 70 54.0231 70 34.8026C70 15.5821 54.3297 0 35 0ZM35 47.2329C27.406 47.2329 21.2511 41.1126 21.2511 33.5615C21.2511 26.0079 27.406 19.8875 35 19.8875C42.594 19.8875 48.7489 26.0078 48.7489 33.5615C48.7489 41.1094 42.594 47.2329 35 47.2329Z" fill="#7B939B"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M35 3C17.3113 3 3 17.2548 3 34.8026C3 38.9184 4.65645 44.149 7.50133 49.8916C10.3138 55.5688 14.1421 61.4595 18.1522 66.8097C22.1602 72.1572 26.2959 76.8934 29.6814 80.2652C31.3801 81.957 32.8417 83.257 33.9692 84.1114C34.3923 84.4319 34.7353 84.6644 35 84.8242C35.2647 84.6644 35.6077 84.4319 36.0308 84.1114C37.1583 83.257 38.6199 81.957 40.3186 80.2652C43.7041 76.8934 47.8398 72.1572 51.8478 66.8097C55.8579 61.4595 59.6862 55.5688 62.4987 49.8916C65.3435 44.149 67 38.9184 67 34.8026C67 17.2549 52.6889 3 35 3ZM34.543 85.0627C34.5431 85.0624 34.5501 85.0596 34.5636 85.0554C34.5496 85.0609 34.5429 85.063 34.543 85.0627ZM35.4364 85.0554C35.4499 85.0596 35.4569 85.0624 35.457 85.0627C35.4572 85.0631 35.4504 85.061 35.4364 85.0554ZM35 50.2329C25.7652 50.2329 18.2511 42.7855 18.2511 33.5615C18.2511 24.3355 25.7648 16.8875 35 16.8875C44.2352 16.8875 51.7489 24.3353 51.7489 33.5615C51.7489 42.7817 44.2353 50.2329 35 50.2329ZM0 34.8026C0 15.5819 15.6705 0 35 0C54.3297 0 70 15.5821 70 34.8026C70 54.0231 39.6623 88 35 88C30.3377 88 0 54.0231 0 34.8026ZM21.2511 33.5615C21.2511 41.1126 27.406 47.2329 35 47.2329C42.594 47.2329 48.7489 41.1094 48.7489 33.5615C48.7489 26.0078 42.594 19.8875 35 19.8875C27.406 19.8875 21.2511 26.0079 21.2511 33.5615Z" fill="white"/>
  </svg>
  `,

  markerIcon: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 9.48264C18.5 11.156 18.2433 12.324 17.339 13.8289C16.4097 15.3756 14.8032 17.2663 12.0033 20.2688C9.19984 17.2661 7.59173 15.3752 6.66161 13.8286C5.75667 12.3238 5.5 11.1559 5.5 9.48264C5.5 6.42376 8.33729 3.5 12 3.5C15.6627 3.5 18.5 6.42376 18.5 9.48264ZM20 9.48264C20 13.2921 18.7554 15.2558 12.732 21.6858C12.3389 22.1055 11.6687 22.1057 11.2753 21.6862C5.24465 15.2559 4 13.2922 4 9.48264C4 5.5243 7.58172 2 12 2C16.4183 2 20 5.5243 20 9.48264ZM13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.1716 11.5 10.5 10.8284 10.5 10C10.5 9.17157 11.1716 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z" fill="#7B939B"/>
  </svg>
  `,

  linkIcon: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 5.5H11V4H7C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V13H18.5V17C18.5 17.8284 17.8284 18.5 17 18.5H7C6.17157 18.5 5.5 17.8284 5.5 17V7C5.5 6.17157 6.17157 5.5 7 5.5ZM15 5.5L17.4394 5.5L8.96967 13.9697L10.0303 15.0303L18.5 6.56072V9.00001H20V4.75C20 4.33579 19.6642 4.00001 19.25 4L15 4L15 5.5Z" fill="#7B939B"/>
  </svg>
  `,

  phone: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7876 3.00093C3.77514 3.00031 3.76261 3 3.75 3C3.33579 3 3 3.33579 3 3.75V5.51761C3 14.0672 9.93324 20.9977 18.4825 20.9977H20.2504C20.6646 20.9977 21.0004 20.6619 21.0004 20.2477C21.0004 20.2347 21.0001 20.2219 20.9994 20.2091V16.6397C20.9994 16.3297 20.8087 16.0516 20.5194 15.94L16.27 14.3003C16.0181 14.2031 15.7334 14.2488 15.5246 14.42L11.5621 17.6678C9.38457 16.4252 7.5732 14.6142 6.33028 12.437L9.57985 8.47568C9.75123 8.26675 9.79698 7.98179 9.6996 7.72973L8.05808 3.48065C7.9464 3.19155 7.6684 3.00093 7.35848 3.00093L3.7876 3.00093ZM5.60014 10.962L8.14281 7.86239L6.8442 4.50093L4.5 4.50093V5.51761C4.5 7.44887 4.89176 9.28868 5.60014 10.962ZM18.4825 19.4977C16.5512 19.4977 14.7112 19.1061 13.0376 18.3979L16.1376 15.857L19.4994 17.1542V19.4977H18.4825Z" fill="#7B939B"/>
  </svg>
  `,

  emailIcon: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 7.47226V17C3.5 17.8284 4.17157 18.5 5 18.5H19C19.8284 18.5 20.5 17.8284 20.5 17V7.47225L12.5057 14.7726C12.2193 15.0341 11.7807 15.0341 11.4943 14.7726L3.5 7.47226ZM20.4353 5.5H3.5647L12 13.2031L20.4353 5.5ZM2 5C2 4.44772 2.44772 4 3 4H21C21.5523 4 22 4.44772 22 5V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V5Z" fill="#7B939B"/>
  </svg>
  `,

  download: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8.5H9.5V7V4.5H14.5V7V8.5H16H17.8787L12 14.3787L6.12132 8.5H8ZM8 4V5.5V7H6.5H4.91421C4.02331 7 3.57714 8.07714 4.20711 8.70711L11.2929 15.7929C11.6834 16.1834 12.3166 16.1834 12.7071 15.7929L19.7929 8.70711C20.4229 8.07714 19.9767 7 19.0858 7H17.5H16V5.5V4C16 3.44772 15.5523 3 15 3H9C8.44772 3 8 3.44772 8 4ZM2 19V16H3.5V19C3.5 19.2761 3.72386 19.5 4 19.5H20C20.2761 19.5 20.5 19.2761 20.5 19V16H22V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19Z" fill="#273A64"/>
  </svg>
  `,

  searchIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 11C17.5 14.5899 14.5899 17.5 11 17.5C7.41015 17.5 4.5 14.5899 4.5 11C4.5 7.41015 7.41015 4.5 11 4.5C14.5899 4.5 17.5 7.41015 17.5 11ZM16.1018 17.1624C14.717 18.3101 12.9391 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 12.9391 18.3101 14.717 17.1624 16.1018L20.9991 19.9384L19.9384 20.9991L16.1018 17.1624Z" fill="#101828" fill-opacity="0.5"/>
  </svg>
  `,

  closeIcon: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9392 12.0006L6.84766 16.0921L7.90831 17.1527L11.9998 13.0612L16.0913 17.1527L17.152 16.0921L13.0605 12.0006L17.1519 7.90905L16.0913 6.84839L11.9998 10.9399L7.90833 6.84839L6.84767 7.90905L10.9392 12.0006Z" fill="#101828" fill-opacity="0.5"/>
  </svg>
  `
}
